/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import Code from "../../common/Code";
import Footer from "../../common/Footer";
import Intro from "../../common/Intro";
import Page from "../../common/Page";
import Meta from "../../common/Meta";
import Section from "../../common/Section";
import KuromojiDemo from "../../kuromoji/KuromojiDemoSection";
import KuromojiHero from "../../kuromoji/KuromojiHero";
import KuromojiSample from "../../kuromoji/KuromojiSample";
import Divider from "../../common/typography/Divider";
import {H1, H2} from "../../common/typography/Headings";

const KuromojiJa = () => (
    <Page path="/ja/kuromoji/" lang="ja" title="kuromoji">
        <Meta name="description">Javaで開発したオープンソース日本語形態素解析エンジン</Meta>

        <KuromojiHero>
            <H1>Kuromoji</H1>
            <p>Javaで開発したオープンソース日本語形態素解析エンジン</p>
        </KuromojiHero>

        <Intro title="機能" />

        <Section title="複合語の分割" grey>
            <p>Kuromojiは日本語のテキストを形態素と呼ばれる単語に分割します。</p>
            <KuromojiSample>
                吾輩は猫である。
                <span>→</span>&nbsp;&nbsp; 吾輩&nbsp;&nbsp; は&nbsp;&nbsp; 猫&nbsp;&nbsp;
                で&nbsp;&nbsp; ある&nbsp;&nbsp; 。
            </KuromojiSample>
        </Section>

        <Section title="品詞のタグ付け">
            <p>単語一つ一つに名詞、動詞、形容詞のような品詞を付けます。</p>
            <KuromojiSample grey spaceRt>
                <ruby>
                    相撲
                    <rt>名詞</rt>を<rt>助詞</rt>
                    見る
                    <rt>動詞</rt>の<rt>助詞</rt>が<rt>助詞</rt>
                    好き
                    <rt>形容動詞</rt>
                    です
                    <rt>助動詞</rt>。<rt>記号</rt>
                </ruby>
            </KuromojiSample>
        </Section>

        <Section title="見出し化" grey>
            <p>動詞と形容詞の基本形を表示することもできます。</p>
            <KuromojiSample>
                <table>
                    <thead>
                        <tr>
                            <th>表示形</th>
                            <th>基本形</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>食べたい</td>
                            <td>食べる</td>
                        </tr>
                        <tr>
                            <td>楽しくない</td>
                            <td>楽しい</td>
                        </tr>
                        <tr>
                            <td>帰りました</td>
                            <td>帰る</td>
                        </tr>
                    </tbody>
                </table>
            </KuromojiSample>
        </Section>

        <Section title="読み方">
            <p>漢字の読み方も抽出できます。</p>
            <KuromojiSample grey>
                <ruby>
                    親譲
                    <rt>おやゆず</rt>
                </ruby>
                りの
                <ruby>
                    無鉄砲
                    <rt>むてっぽう</rt>
                </ruby>
                で
                <ruby>
                    小供
                    <rt>こども</rt>
                </ruby>
                の
                <ruby>
                    時<rt>とき</rt>
                </ruby>
                から
                <ruby>
                    損<rt>そん</rt>
                </ruby>
                <ruby>ばかりしている</ruby>
            </KuromojiSample>
        </Section>

        <Section title="検索モード" grey>
            <p>
                Kuromojiは検索アプリケーションに適した「検索モード」があります。検索モードでは複合でも一致するようにさらに細かく
                形態素を分割しています。
            </p>

            <p>
                例えば、「空港」を検索する場合、「関西国際空港」でも一致して欲しいのですが、多くの形態素解析エンジンは
                「関西国際空港」を一つの形態素として扱っているため検索した際一致しなくなってしまいます。
            </p>
        </Section>

        <Section title="幅広い辞書サポート">
            <p>
                Kuromojiは多くの辞書バックエンドに対応しています。
                アプリケーションに寄ってipadic、jumandic、unidicなどが使えます。
            </p>
        </Section>

        <Section title="オープンソース" grey>
            <p>KuromojiはApacheライセンス、バージョン2.0で提供しています。</p>
        </Section>

        <Section title="検索エンジンのインテグレーション">
            <p>
                KuromojiはApache Lucene及びApache
                Solrの日本語対応の土台となっています。Elasticsearchにも使われています。
            </p>
        </Section>

        <KuromojiDemo title="デモ" lang="ja" />

        <Section title="導入">
            <p>
                Kuromojiは下記の機能を持った、使い勝手のいい自己完成型の日本語形態素解析エンジンです。
            </p>
            <ul>
                <li>単語の分割。文章を形態素と呼ばれる単語に分割します。</li>
                <li>品詞タグ付け。単語を名詞、動詞、助詞、形容詞などに分けます。</li>
                <li>基本形抽出。活用の動詞、形容詞から辞書の基本形の見出しを抽出します。</li>
                <li>読み方。漢字の読み方を抽出します。</li>
            </ul>
            <p>他の機能はあります。詳細は各辞書のToken classをご参照ください。</p>

            <Divider />

            <H2>実用例</H2>
            <p>
                この例では、Kuromojiの簡単な使い方を表示しています。
                入力文章を形態素に分割してからそれぞれの形態素のフィーチャーを出力します。
            </p>
            <Code lang="java">{`
                package com.atilika.kuromoji.example;

                import com.atilika.kuromoji.ipadic.Token;
                import com.atilika.kuromoji.ipadic.Tokenizer;
                import java.util.List;

                public class KuromojiExample {
                    public static void main(String[] args) {
                        Tokenizer tokenizer = new Tokenizer() ;
                        List<Token> tokens = tokenizer.tokenize("お寿司が食べたい。");
                        for (Token token : tokens) {
                            System.out.println(token.getSurface() + "\\t" + token.getAllFeatures());
                        }
                    }
                }
            `}</Code>
            <p>プロジェクトをビルドする前に下記の依存情報をpom.xmlに追記してください。</p>
            <Code lang="xml">{`
                <dependency>
                    <groupId>com.atilika.kuromoji</groupId>
                    <artifactId>kuromoji-ipadic</artifactId>
                    <version>0.9.0</version>
                </dependency>
            `}</Code>
        </Section>

        <Footer lang="ja" />
    </Page>
);

export default memo(KuromojiJa);
